import { useState } from "react";
import BUYNOW from "../../assets/images/buy-now.png";
import { LessonDataI } from "../quiz/typesAndEnums";
import CodePurchase from "./purchaseMethods/CodePurchase";
import FawryPurchase from "./purchaseMethods/FawryPurchase";
import FreePurchase from "./purchaseMethods/FreePurchase";
import WalletPurchase from "./purchaseMethods/WalletPurchase";

export enum PurchaseMethods {
  CODE = "code",
  WALLET = "wallet",
  FAWRY = "fawry",
  VODAFONE_CASH = "vodafoneCash",
}

const Purchase = ({
  lessonData,
  isMobile,
  showImg = true,
}: {
  lessonData: LessonDataI;
  isMobile: boolean;
  showImg?: boolean;
}) => {
  const [purchaseBlock, setPurchaseBlock] = useState(null);

  if (lessonData.price === 0) return <FreePurchase lessonData={lessonData} />;

  return (
    <div className="purchase-lesson">
      <div className="d-flex">
        {showImg && (
          <img className="m-auto w-50" src={BUYNOW} alt="اشتري الان" />
        )}
      </div>
      <div className="d-flex my-3">
        <div className="btn-group m-auto w-75 mb-4">
          <select
            className="m-auto rounded-ea purchase-options"
            onChange={(e) => setPurchaseBlock(e.target.value)}
          >
            <option selected>اختر وسيلة الدفع</option>
            {lessonData.code && (
              <option value={PurchaseMethods.CODE}>كود</option>
            )}
            {lessonData.wallet && (
              <option value={PurchaseMethods.WALLET}>رصيد المحفظة</option>
            )}
            {lessonData.fawry && (
              <option value={PurchaseMethods.FAWRY}>فوري</option>
            )}
            {lessonData.vodafone_cash && (
              <option value={PurchaseMethods.VODAFONE_CASH}>فودافون كاش</option>
            )}
          </select>
        </div>
      </div>
      <div className="purchase-block w-100">
        {purchaseBlock === PurchaseMethods.CODE && (
          <CodePurchase lessonData={lessonData} isMobile={isMobile} />
        )}
        {purchaseBlock === PurchaseMethods.WALLET && (
          <WalletPurchase lessonData={lessonData} isMobile={isMobile} />
        )}
        {purchaseBlock === PurchaseMethods.FAWRY && (
          <FawryPurchase lessonData={lessonData} isMobile={isMobile} />
        )}
      </div>
    </div>
  );
};

export default Purchase;
