import {
  LessonDataI,
  VideoData,
  VideoProvider,
} from "../../../quiz/typesAndEnums";
import { decrementVideoView } from "../utils/decrementVideoView";
import useCheckIsMobile from "../utils/useCheckIsMobile";

const useVideoHandler = ({ lessonData }: { lessonData: LessonDataI }) => {
  const isAuthor = !!lessonData.is_author;
  const isPurchased = lessonData.is_purchased;
  const unlimitedViews = lessonData.remaining_views === -1;
  const unlimitedExpiryDate = lessonData.expiry_date === -1;
  const isViewsExpired = lessonData.remaining_views < 1 && !unlimitedViews;
  const isDateExpired =
    lessonData.expiry_date < Date.now() && !unlimitedExpiryDate;

  const { isMobile } = useCheckIsMobile();

  let isExpired = true;

  if (!isViewsExpired && !isDateExpired && isPurchased) isExpired = false;
  if (isAuthor) isExpired = false;

  const videoExist = !!lessonData.videos_data;

  const files = lessonData?.lesson_files;
  const expirtDate = lessonData.expiry_date;

  const location = window.location.hostname;
  const isProtected =
    lessonData.xvast_protection &&
    window.navigator.userAgent.indexOf("Xvast") === -1 &&
    location !== "localhost" &&
    !isMobile;

  const onlyOneVideo = JSON.parse(lessonData.videos_data).length === 1;

  const isVimeo = lessonData.video_host === "vimeo";

  // custom checks for mohamedgalal.com
  const getVideoID = (video: VideoData) => {
    // isGalalsOffline
    if (
      ["mohamedgalal.com", "localhost"].includes(window.location.hostname) &&
      video?.video_id2 &&
      lessonData.is_offline_purchase
    )
      return video?.video_id2;
    return video?.video_id;
  };

  const isBunny = (url: string) => {
    return url.indexOf("play/") !== -1 ? true : false;
  };

  const handleVideoView = () =>
    decrementVideoView({
      onlyOneVideo,
      unlimitedExpiryDate,
      lesson_id: lessonData.lesson_id,
    });

  const getVideoProvider = (url: string) => {
    if (isVimeo) return VideoProvider.VIMEO;
    if (isBunny(url)) return VideoProvider.BUNNY;
    // if (isMobile) return VideoProvider.PLYR;
    return VideoProvider.ELITE;
  };

  const parsedVideo: VideoData[] = JSON.parse(lessonData.videos_data);

  const videos = parsedVideo.map((vid) => {
    return {
      ...vid,
      provider: getVideoProvider(getVideoID(vid)),
    };
  });

  return {
    isExpired,
    videoExist,
    videos,
    files,
    expirtDate,
    isProtected,
    unlimitedViews,
    unlimitedExpiryDate,
    onlyOneVideo,
    isVimeo,
    isMobile,
    getVideoID,
    handleVideoView,
    isBunny,
  };
};

export default useVideoHandler;
