import Animation from "../../../Animation";
import { LessonDataI } from "../../../quiz/typesAndEnums";
import LessonCountdown from "../../LessonCountdown";

const LessonPageHead = ({ lessonData }: { lessonData: LessonDataI }) => {
  return (
    <div className="pt-4 pe-4 ps-4 pb-2">
      <div className="row">
        {/* {lessonData.newly_purchased && (
          <div className="alert alert-success rounded-ea w-100" role="alert">
            تم شراء الحصة بنجاح
          </div>
        )} */}
        <div className="col-md-8 col-12">
          <h3 className="my-auto fw-bold text-333">{lessonData.title}</h3>
        </div>
        <div className="col-md-4 col-12 d-flex justify-content-end align-middle">
          {/* <p className="my-auto">
                      تاريخ النشر : { lessonData.date}
                    </p> */}
        </div>
      </div>
      {lessonData.is_purchased && !lessonData.is_author && (
        <div className="d-flex row">
          {lessonData.expiry_date !== -1 && (
            <div className="res-timers col-md-6 col-12 m-auto d-inline mt-2 py-1 px-auto alert alert-danger rounded-ea mb-0">
              {
                <Animation>
                  <LessonCountdown targetDate={lessonData.expiry_date} />
                </Animation>
              }
            </div>
          )}
          <div className="res-timers col-md-6 col-12 d-inline mx-auto mt-2 py-1 px-auto bg-ea-base-2 border-ea rounded-ea text-ea-primary">
            {`مرات المشاهدة المتبقية (${
              lessonData.remaining_views === -1
                ? "غير محدود"
                : lessonData.remaining_views < 1
                ? "نفذت المشاهدات"
                : lessonData.remaining_views
            })`}
          </div>
        </div>
      )}
    </div>
  );
};

export default LessonPageHead;
