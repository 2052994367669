import Purchase from "../../../orders/Purchase";
import { LessonDataI } from "../../../quiz/typesAndEnums";

const LessonExpired = ({
  lessonData,
  isMobile,
}: {
  lessonData: LessonDataI;
  isMobile: boolean;
}) => {
  return (
    <div className="alert alert-danger m-5 rounded-ea" role="alert">
      <p
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        إنتهت صلاحية الحصة
      </p>
      <p
        style={{
          textAlign: "center",
        }}
      >
        يمكنك شراء الحصة مجدداً
      </p>
      <Purchase lessonData={lessonData} isMobile={isMobile} showImg={false} />
    </div>
  );
};

export default LessonExpired;
