import { useEffect, useState } from "react";
import { NavLink as Link, useNavigate } from "react-router-dom";
import { endpoints } from "../../hooks/endpoints";
import Logout from "../../hooks/useLogout";
import { getToken } from "../../hooks/utils";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  currentTeacherData,
  setPortal,
  setUserProfileData,
  userData,
} from "../../store/slices/storeSlice";

export let deferredPrompt;

const Navbar = () => {
  const { user } = useAppSelector((state) => state.store);
  const [mainPage, setMainPage] = useState("/");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const userData = JSON.parse(
      localStorage.getItem(`${window.location.hostname}_user`)
    );
    if (
      userData &&
      userData?.data_completed !== "1" &&
      !["teacher", "admin"].includes(userData?.role ?? "")
    )
      navigate("/complete-info");
  }, [window.location.pathname]);

  useEffect(() => {
    fetch(endpoints.me, {
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.uid) {
          dispatch(userData(res));
        }
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem(`${window.location.hostname}_user`);
        navigate("/");
      });
  }, []);

  useEffect(() => {
    if (user) {
      // checkIfTeacher();
      setMainPage("/school-grades");
    } else {
      setMainPage("/");
    }
  }, [user]);

  const { portalRefetch, userRefetch, portal } = useAppSelector(
    (state) => state.store
  );

  useEffect(() => {
    fetch(
      endpoints.getPortal +
        `?portal=${
          window.location.hostname !== "localhost"
            ? window.location.hostname
            : // : "localhost"
              "mohamedgalal.com"
        }`,
      {
        method: "GET",
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setPortal(result));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [portalRefetch]);

  useEffect(() => {
    if (user) {
      fetch(endpoints.getUser, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        redirect: "follow",
      })
        .then((response) => response.json())
        .then((result) => {
          dispatch(setUserProfileData(result));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userRefetch, user]);

  const userProfileData = useAppSelector(
    (state) => state.store.userProfileData
  );

  useEffect(() => {
    console.log("current portal after update", portal);

    if (portal) {
      // update teacher data
      dispatch(currentTeacherData(portal?.category));
      const logo = document.getElementById("logo-webp");
      logo.src = portal?.logoUrl;
      const favicon = document.getElementById("teacher-favicon");
      favicon.href = portal?.favicon;
      document.title = portal?.name;
    }
  }, [portal]);

  // window.addEventListener("beforeinstallprompt", (e) => {
  //   // Prevent the mini-info bar from appearing on mobile
  //   e.preventDefault();
  //   // Stash the event so it can be triggered later
  //   deferredPrompt = e;
  // });

  return (
    <nav className="navbar navbar-expand-lg navbar-light shadow-sm bg-light bg-gradient">
      <div className="container-fluid">
        <img id="logo-webp" className="mx-4" src="" alt="" height="50" />
        <button
          className="navbar-toggler bg-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav fw-bold">
            <li className="nav-item">
              {user && (
                <Link className="nav-link grow" to={mainPage}>
                  الرئيسية
                </Link>
              )}
            </li>
            <li className="nav-item">
              {user && ["teacher", "admin"].includes(user.role) && (
                <Link className="nav-link grow" to="/dashboard">
                  داشبورد المدرس
                </Link>
              )}
            </li>
            <li className="nav-item">
              {user && (
                <Link className="nav-link grow" to="/account">
                  حسابي
                </Link>
              )}
            </li>
            {/* <li className="nav-item">
          {user && <Link id="my-wallet" className="nav-link grow" aria-current="page" to="/wallet">
            محفظتي
          </Link>}
        </li> */}
            <li className="nav-item">
              {user && (
                <Link id="my-quizzes" className="nav-link grow" to="/grades">
                  امتحاناتي
                </Link>
              )}
            </li>
            <li className="nav-item font-weight-bold">
              {user && (
                <Link
                  id="my-purchases"
                  className="nav-link grow"
                  to="/invoices"
                >
                  مشترياتي
                </Link>
              )}
            </li>
            {/* {(domain === "localhost" || domain.includes("galal")) && (
              <li className="nav-item font-weight-bold">
                {user && (
                  <Link
                    id="my-purchases"
                    className="nav-link p-2 text-danger grow"
                    to="/ai-assistant"
                  >
                    AI Assistant
                  </Link>
                )}
              </li>
            )} */}
            <li id="log-me-out" className="nav-item me-3">
              {window.location.pathname !== "/" && (
                <Logout className="nav-link" />
              )}
            </li>

            {/* <li id="log-me-out" className="nav-item me-3">
              <button
                onClick={async () => {
                  if (deferredPrompt) {
                    // Show the install prompt
                    deferredPrompt.prompt();
                    // Wait for the user to respond to the prompt
                    deferredPrompt.userChoice.then((choiceResult) => {
                      if (choiceResult.outcome === "accepted") {
                        console.log("User accepted the install prompt");
                      } else {
                        console.log("User dismissed the install prompt");
                      }
                      // Clear the deferredPrompt variable since it can only be used once
                      deferredPrompt = null;
                    });
                  }
                }}
                className="btn ea-btn rounded-ea"
              >
                PWA
              </button>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
